import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AppStateService } from '@core/services/app-state.service'; 
import { environment } from '@environement/environment';
import { Authentication } from '@hsi/ui-core';
import { SettingsComponent } from '../settings/settings.component';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  constructor(
    private dialog:MatDialog, 
    public appStateService:AppStateService,
    public authenticationService:Authentication.Core.AuthenticationService) { }

  videoPlayerUrl = environment.videoPlayer;
  ngOnInit(): void {
  } 
  settingsDialog(){
    let dialogRef = this.dialog.open(SettingsComponent, { width: '400px' });  
  } 
}
