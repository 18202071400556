import { Injectable, NgZone } from '@angular/core';  

/**
 * Shared service used for storing app shell properties.
 */
@Injectable({
  providedIn: 'root'
})
export class AppStateService {
  constructor(private zone: NgZone) { }
  /**
   * Application Loading State
   */ 
   public loading = false;
   private loadingCount = 0;
   /**
    * Increment or decrements the loading queue.
    * @param increment 
    */
   public tickLoading(increment:boolean){ 
      if(increment)
        this.loadingCount += 1;
      else
        this.loadingCount -= 1; 
      this.loading = this.loadingCount > 0;  
   }
   /**
    * Network Connectivity State
    */
   public connectivity = navigator.onLine;
   /**
    * Installable on Current Device
    */
   public installable = false;
   /**
    * Deferred prompt provisioned by the browser at runtime.  See beforeinstallprompt event.
    */
   public installPrompt:any = null;
   /**
    * Update available for install.
    */
   public updateAvailable = false;
   /**
    * Prompt user to force update application.
    */
   public updatePrompt:any = null; 
   /**
    * Toggle state of application nav menu.
    */
   private _displayNavigation:boolean = localStorage.getItem('displayNavigation') === 'true' ? true : false;
   public get displayNavigation():boolean {
      return this._displayNavigation;
   } 
   public set displayNavigation(value){
     this._displayNavigation = value;
     localStorage.setItem('displayNavigation', value ? 'true':'false');
   }
   /**
    * Toggle state of application nav menu slim mode.
    */
   private _slimNavigation:boolean = localStorage.getItem('slimNavigation') === 'true' ? true : false;
   public get slimNavigation():boolean {
      return this._slimNavigation;
   } 
   public set slimNavigation(value){
     this._slimNavigation = value;
     localStorage.setItem('slimNavigation', value ? 'true':'false');
   } 
}
