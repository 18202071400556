import { Injectable } from "@angular/core"
import { Router } from "@angular/router" 
import { UtilityService } from "@core/services/utility.service";

@Injectable({providedIn:'root'})
export default class TokenExpirationHandler{
    constructor(
        private router: Router,
        private utilityService: UtilityService){}          
    onExpiration = ()=> {
        this.router.navigateByUrl('/login');
        this.utilityService.say("Your session has expired.  Please reauthenticate to continue.");
    }
} 