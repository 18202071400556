<div class="container mx-auto lg:w-4/12 flex justify-center items-center" style="margin-top:-48px" >
    <!--Unauthenticated User-->
    <mat-card class="flex-1" *ngIf="!authenticatedUser">
        <form [formGroup]="loginForm" class="flex flex-col gap-2 justify-center mx-auto p-4">   
            <img src="assets/hsi-logo.png" class="mx-auto w-[150px] mb-8"/> 
            <mat-form-field appearance="outline">
                <mat-label>Company</mat-label> 
                <input type="text" matInput formControlName="tenant" [errorStateMatcher]="matcher" placeholder="">
                <mat-hint><!--Hint--></mat-hint>
                <mat-error *ngIf="loginForm.controls.tenant.hasError('email') && !loginForm.controls.tenant.hasError('required')">
                Please enter your Company
                </mat-error>
                <mat-error *ngIf="loginForm.controls.tenant.hasError('required')">
                Company is <strong>required</strong>
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Login Id</mat-label>
                <mat-icon matSuffix>account_circle</mat-icon>
                <input type="text" matInput formControlName="loginId" [errorStateMatcher]="matcher" placeholder="">
                <mat-hint><!--Hint--></mat-hint>
                <mat-error *ngIf="loginForm.controls.loginId.hasError('email') && !loginForm.controls.loginId.hasError('required')">
                Please enter your Login Id
                </mat-error>
                <mat-error *ngIf="loginForm.controls.loginId.hasError('required')">
                Login Id is <strong>required</strong>
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Password</mat-label>
                <mat-icon matSuffix>password</mat-icon>
                <input type="password" matInput formControlName="password" [errorStateMatcher]="matcher">  
                <mat-error *ngIf="loginForm.controls.password.hasError('required')">
                Password is <strong>required</strong>
                </mat-error>
            </mat-form-field> 
            <div class="pt-4">
                <button (click)="submit()" mat-raised-button color="primary" class="w-full">Log In</button>
            </div>  
        </form>
    </mat-card> 
    <!--AuthenticatedUser-->
    <mat-card class="flex-1 flex-col" *ngIf="authenticatedUser">
        <img src="assets/hsi-logo.png" class="mx-auto w-[200px] mb-4"/> 
        <div class="flex justify-center items-center flex-col w-full">
            <div>You are currently logged in as:</div>
            <div style="display:flex;flex-direction:row;justify-content:flex-start;align-items:center;margin-top:16px;">
                <i class="material-icons" aria-hidden="true" style="font-size:38px;color:#1976d2;">account_circle</i>
                <div style="text-align:left;margin-left:8px;">
                    <div><b>{{authenticatedUser.name}}</b></div>
                    <small>{{authenticatedUser.customerName}}</small>
                </div>
            </div>
            <div style="font-size:14px;margin-top:16px;">
                Your existing session will expire on: 
            </div>
            <div style="font-size:14px;margin-top:8px;">
                <b>{{authenticatedUser.expirationDateTime.toLocaleString("en-US")}}</b>            
            </div>
            <div class="w-full mt-8" style="padding-top:16px; text-align:left;font-size:14px;border-top:solid 2px #f1f1f1;">
                To proceed as <b>{{authenticatedUser.name}}</b> click Continue.  Click Log Out to end your session or change users.
            </div>
        </div> 
        <div class="pt-4 flex flex-row gap-2">
            <button (click)="continue()" mat-raised-button color="primary" class="w-full">Continue</button> 
            <button (click)="logout()" mat-raised-button color="warn" class="w-full">Log Out</button>
        </div>  
    </mat-card> 
</div>