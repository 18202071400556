import { animate, group, query, style, transition, trigger } from "@angular/animations"; 
function getPadding(){ 
    return window.innerWidth < 640 ? '32px' : '64px';
}
export const fadeAnimation = 
  trigger('fadeAnimation', [ 
    transition('* => *', [ 
      query(':enter',
        [ 
          style({opacity: 1, position: 'absolute', width:`calc(100% - ${getPadding()})`, height:'calc(100% - 75px)', 'z-index':10})
        ],
        {optional: true}
      ),  
      query(':leave',
        [
          style({opacity: 1, position:'absolute', width:`calc(100% - ${getPadding()})`, height:'calc(100% - 75px)', 'z-index':11}),
          animate('.25s', style({opacity: 0}))
        ],
        {optional: true}
      ) 
    ]) 
  ]);