import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiModule } from 'src/app/ui/ui.module';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { RouterModule } from '@angular/router';
import { InstallationComponent } from './components/installation/installation.component';
import { UpdateComponent } from './components/update/update.component';
import { ConnectivityComponent } from './components/connectivity/connectivity.component';
import { UpdateDialogComponent } from './components/update-dialog/update-dialog.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { HighlightPipe } from './pipes/highlight.pipe';
import { SettingsComponent } from './components/settings/settings.component'; 
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToolbarMobileComponent } from './components/toolbar-mobile/toolbar-mobile.component';
import { AuthenticationComponent } from './components/authentication/authentication.component'; 
 
@NgModule({
  declarations: [
    ConnectivityComponent,
    InstallationComponent,
    ToolbarComponent,
    UpdateComponent,
    UpdateDialogComponent,
    NavigationComponent,
    HighlightPipe,
    SettingsComponent,
    ToolbarMobileComponent,
    AuthenticationComponent
  ],
  imports: [
    CommonModule,
    UiModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    ConnectivityComponent,
    InstallationComponent,
    ToolbarComponent,
    ToolbarMobileComponent,
    UpdateComponent,
    NavigationComponent,
    HighlightPipe,
    AuthenticationComponent
  ]
})
export class CoreModule { }
