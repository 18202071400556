import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DialogConfirmation } from '@core/interfaces/dialogConfirmation';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit { 
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogConfirmation) { }

  ngOnInit(): void {
  }

}
