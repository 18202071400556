import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AppStateService } from '@core/services/app-state.service';
import { Authentication } from '@hsi/ui-core';
import { UpdateDialogComponent } from '../update-dialog/update-dialog.component'; 
import { Router } from '@angular/router';
import { SettingsComponent } from '../settings/settings.component'; 
import { environment } from '@environement/environment';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: []
})
export class ToolbarComponent implements OnInit { 
  constructor(  
    public authenticationService:Authentication.Core.AuthenticationService,
    public appStateService:AppStateService, 
    public router:Router,
    private dialog:MatDialog) { }
  
  title = environment.title;

  ngOnInit(): void {
    
  } 

  toggleFullScreen(){    
    const elem =  document.documentElement;
    if (!document.fullscreenElement)
        elem.requestFullscreen();
    else 
      document.exitFullscreen();    
  }
  
  settingsDialog(){
    let dialogRef = this.dialog.open(SettingsComponent, { width: '400px' });  
  }

  updateDialog(){
    let dialogRef = this.dialog.open(UpdateDialogComponent, { width: '400px' });  
  }
}
