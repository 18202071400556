import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { FormErrorHandler } from '@core/handlers/form-error-handler';
import { Authentication } from '@hsi/ui-core'; 
import { Router } from '@angular/router';  
import { UtilityService } from '@core/services/utility.service'; 

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss']
})
export class AuthenticationComponent implements OnInit {

  constructor(
    private authenticationService: Authentication.Core.AuthenticationService,  
    private router: Router,
    private utilityService: UtilityService) {}

  authenticatedUser = this.authenticationService.authenticatedUser;
  loginForm = new UntypedFormGroup({
    tenant: new UntypedFormControl({value:'HSI', disabled:true}, [Validators.required]),
    loginId: new UntypedFormControl('', [Validators.required]),
    password: new UntypedFormControl('', [Validators.required])
  });
  matcher = new FormErrorHandler();   
  ngOnInit(): void {
  }

  submit(): void { 
    if(!this.loginForm.valid){ 
      this.utilityService.say("Please fill out all required form fields.");
      return
    }
    let credentials: Authentication.Core.HsiAuthenticationCredentials = {
      loginId: this.loginForm.controls.loginId.value,
      password: this.loginForm.controls.password.value,
      tenant: this.loginForm.controls.tenant.value
    };
    let onError = (error: any) => this.utilityService.say('Authentication failed.  Please try again.');
    let onSuccess = async ()=> { 
      this.continue();
    };
    this.authenticationService.authenticate(credentials)
      .then(onSuccess, onError);
  }
  logout():void{
    this.authenticationService.logOut();
    this.authenticatedUser = this.authenticationService.authenticatedUser;
  }
  continue():void{  
    this.router.navigate(['/course/index']);
  }
}
