import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AppStateService } from '@core/services/app-state.service';

@Component({
  selector: 'app-update-dialog',
  templateUrl: './update-dialog.component.html',
  styleUrls: ['./update-dialog.component.scss']
})
export class UpdateDialogComponent implements OnInit {

  constructor(
    private appStateService:AppStateService,
    private dialogRef:MatDialogRef<UpdateDialogComponent>) { }
  ngOnInit(): void {
  }

  update(){
    this.appStateService.updatePrompt();
    this.dialogRef.close();
  }
}
