<app-card-layout>
    <ng-container ngProjectAs="[header-icon]">phonelink_setup</ng-container>
    <ng-container ngProjectAs="[header-title]">Application Settings</ng-container>
    <ng-container ngProjectAs="[header-description]">Manage Details & Configuration</ng-container>
    <ng-container ngProjectAs="[header-actions]">
        <button mat-icon-button aria-label="Audit Configuration Menu" mat-dialog-close><mat-icon>close</mat-icon></button> 
    </ng-container>  
    <ng-container ngProjectAs="[body-content]">
        <mat-form-field appearance="outline">
            <mat-label>App Theme</mat-label>
            <mat-select [(ngModel)]="appTheme"> 
                <mat-option value="lightMode">Light Mode</mat-option> 
                <mat-option value="darkMode">Dark Mode</mat-option> 
            </mat-select> 
        </mat-form-field>  
    </ng-container>
</app-card-layout> 