import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar'; 
import { fromEvent, Observable, Subscription } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  constructor(private snackBar:MatSnackBar) {   
      this._resizeObservable$ = fromEvent(window, 'resize')
      this._resizeObservable$.subscribe( evt => { 
        this.screenWidth = window.innerWidth;
        this.screenHeight = window.innerHeight;
      });
  } 
  private _resizeObservable$: Observable<Event>; 
  /**
   * Window width dimension;
   */
  screenWidth:number = window.innerWidth;
  /**
   * Window height dimension
   */
  screenHeight:number = window.innerHeight;
  /**
   * Material Snackbar wrapper used for displaying system messages.
   * @param message 
   */
  say(message:string){
    this.snackBar.open(message, "Ok", { duration: 5000 });
  }
  /**
   * Animates an element with a predefined class from animate.css library.
   * @param element 
   * @param animation 
   * @param duration 
   * @returns 
   */
  animateElement = (element:any, animation:string, duration:string = '0.5s') => 
    new Promise((resolve, reject) => {
      const hide:boolean = animation.indexOf('Out') !== -1;
      const prefix = 'animate__';
      const animationName = `${prefix}${animation}`;
      const node = document.querySelector(element); 
      
      node.style.setProperty('--animate-duration', duration);
      if(!hide)
        node.classList.remove('hide');   
      node.classList.add(`${prefix}animated`, animationName); 
      function handleAnimationEnd(event:any) {
        event.stopPropagation();
        node.classList.remove(`${prefix}animated`, animationName);
        if(hide)
          node.classList.add('hide');
        else
          node.classList.remove('hide');
        resolve('Animation ended');
      } 
      node.addEventListener('animationend', handleAnimationEnd, {once: true});
  }); 
  
  platform:string = [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform) || (navigator.userAgent.includes("Mac") && "ontouchend" in document) ? 
      'ios' : 
      'other';
 
  getHeightByAspectRatio(el:HTMLElement, ratio:string, columnCount:number){
    let aspectRatio = {
      "1x1": 1,
      "1x2": 2,
      "2x1": 0.5,
      "4x3": 0.75,
      "16x9": 0.5625
    } as {[key:string]:number};
    let gapWidth = columnCount === 1 ? 1 : 16;
    let gapTotal = (columnCount + 1) * gapWidth;
    let colSize = 1/columnCount;
    let viewPort = el!.offsetWidth;
    let colWidth = (viewPort * colSize) - (gapTotal * colSize);
    let colHeight = colWidth * aspectRatio[ratio];
    return Math.ceil(colHeight); 
  }
}
