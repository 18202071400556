import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-layout',
  templateUrl: './card-layout.component.html',
  styleUrls: ['./card-layout.component.scss']
})
export class CardLayoutComponent implements OnInit { 
/** 
 *### Example Implementation
 * ```html
 *
 <app-card-layout status="default" size="default">
    <ng-container ngProjectAs="[header-icon]">material_icon_name</ng-container>
    <ng-container ngProjectAs="[header-title]">Title</ng-container>
    <ng-container ngProjectAs="[header-description]">Description</ng-container>
    <ng-container ngProjectAs="[header-actions]">
        <div id="nav-dropdown"> 
            <button mat-icon-button [matMenuTriggerFor]="dropdownMenu" aria-label="My Menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #dropdownMenu="matMenu">
                <button mat-menu-item routerLink="/my-page">
                  <span>Visit My Page</span> 
                </button> 
                <button mat-menu-item (click)="doThing()">
                  <span>Do Thing</span> 
                </button> 
              </mat-menu>
        </div>  
    </ng-container>  
    <ng-container ngProjectAs="[body-content]"> 
        My Content
    </ng-container>
</app-card-layout>  
 * ```
 */
  constructor() { } 
  /**
   * Indicates whether or not to display header items.
   */
  @Input() header:boolean = true;
  /**
   * Indicates the status of the hearder displayed.  Options: default | error
   */
  @Input() status:string = 'default';
  /**
   * Indicates the size of the hearder displayed.  Options: default | large
   */
  @Input() size:string = 'default';

  get headerSize(){
    return this.size;
  }

  ngOnInit(): void {
  } 
} 