import { AppStateService } from '@core/services/app-state.service';
import { Authentication, Common } from '@hsi/ui-core';

/**
 * Orchestrates @hsi/ui-core MicroserviceClient composition.
 */
export let microserviceClientProvider = {
    provide: Common.Infrastructure.MicroserviceClient,
    deps: [Authentication.Infrastructure.WebStorageTokenProvider, AppStateService],
    useFactory: (webStorageTokenProvider: Authentication.Infrastructure.WebStorageTokenProvider, appStateService: AppStateService) => {  
        return new Common.Infrastructure.MicroserviceClient(webStorageTokenProvider, undefined, 
            (loadingState)=>{
                appStateService.tickLoading(loadingState)
            });       
    },
}; 