import { Component, Inject, OnInit } from '@angular/core';

import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-ui-scanner',
  templateUrl: './ui-scanner.component.html',
  styleUrls: ['./ui-scanner.component.scss']
})
export class UiScannerComponent implements OnInit {

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: { 
      codeMessageHandler: (codeMessage:CodeMessage) => void 
    }) { } 

  codes:CodeMessage[] = [];

  removeCode(index:number){
    this.codes.splice(index,1);
  }
  ngOnInit(): void { 
    // let el = document.getElementsByTagName('mat-bottom-sheet-container')[0] as HTMLElement;
    // el.style.overflow = 'hidden';
    let processMessage = (dto:any) => {   
      if(dto.data.videoHeight){
        let el = document.getElementById('scannerDisplay');
        if(el){
          el.style.height =  `${dto.data.videoHeight}px`; 
        }
        return;
      }
      let codeMessage = new CodeMessage(dto);
      console.log(codeMessage);
      if(!this.codes.some(function(x){
        return x.value === codeMessage.value;
      }))
      {
          this.codes.push(codeMessage); 
          this.data.codeMessageHandler(codeMessage);
          codeMessage.onCallback();
      } 
  };  
  window.addEventListener('message', processMessage);
  window.addEventListener('onmessage', processMessage);
  } 
} 

export class CodeMessage{
  constructor(dto:any){
    this.value = dto.data.text;
    this.codeType = dto.data.format;
    this.hit = false;
    this.display = true;
    this.templateText = "";
    this.templateActionText = "";
    this.templateActionCallback = dto.templateActionCallback; 
  }
  value:string;
  codeType:string;
  hit:boolean;
  display:boolean;
  templateText:string;
  templateActionText:string;
  templateActionCallback:()=>void;
  onCallback = () => {
      if(this.templateActionCallback)
      {
          this.templateActionCallback();
      }
  }
}