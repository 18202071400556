import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser'; 
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { UiModule } from './ui/ui.module';
import { CoreModule } from '@core/core.module';
import { authServiceProvider } from '@core/providers/authentication-service.provider';
import { microserviceClientProvider } from '@core/providers/microservice-client.provider';
import { webStorageTokenProvider } from '@core/providers/web-storage-token.provider';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    CoreModule,
    UiModule
  ],
  providers: [
    authServiceProvider,
    microserviceClientProvider,
    webStorageTokenProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
