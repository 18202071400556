import { Component, HostListener } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { fadeAnimation } from '@core/animations/fade';
import { AppStateService } from '@core/services/app-state.service'; 
import { UtilityService } from '@core/services/utility.service';
import { Authentication } from '@hsi/ui-core'; 
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fadeAnimation]
})
export class AppComponent {
  constructor(
    public router:Router,
    public appStateService:AppStateService,
    public utilityService:UtilityService,
    public authenticationService:Authentication.Core.AuthenticationService){
  }    
  getAnimationData(routerOutlet: RouterOutlet) {
    const routeData = routerOutlet.activatedRouteData['animation'];
    return routeData ? routeData : 'rootPage';
  }
}
