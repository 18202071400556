import { Component, HostListener } from '@angular/core';
import { AppStateService } from '@core/services/app-state.service';

@Component({
  selector: 'app-installation',
  template: '',
  styleUrls: []
})
export class InstallationComponent { 
  constructor(
    private appStateService: AppStateService) { }
  deferredPrompt:any = null;

  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(event:any) {    
    this.deferredPrompt = event;
    event.preventDefault(); 
    console.log(`Application can be installed.`);
    this.appStateService.installable = true;  
    this.appStateService.installPrompt = ()=> {
      this.deferredPrompt.prompt(); 
      this.deferredPrompt.userChoice.then((choiceResult:any) => {
        if(choiceResult.outcome === 'accepted')
        {
          console.log('Install request accepted'); 
          this.appStateService.installable = false;
        }
        else
        {
            console.log('Install request declined');
        }
      });
    };
  }    
}