import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { AppStateService } from '@core/services/app-state.service';
import { UtilityService } from '@core/services/utility.service'; 
import { environment } from '@environement/environment';
import { Authentication } from '@hsi/ui-core';  
import { SettingsComponent } from '../settings/settings.component'; 

@Component({
  selector: 'app-toolbar-mobile',
  templateUrl: './toolbar-mobile.component.html',
  styleUrls: ['./toolbar-mobile.component.scss']
})
export class ToolbarMobileComponent implements OnInit {

  constructor(
    private dialog:MatDialog,
    public router:Router,
    public authenticationService:Authentication.Core.AuthenticationService,
    public appStateService:AppStateService,
    public utilityService:UtilityService) { }

  videoPlayerUrl = environment.videoPlayer;
  
  ngOnInit(): void {
  } 
  
  settingsDialog(){
    let dialogRef = this.dialog.open(SettingsComponent, { width: '400px' });  
  } 
}
