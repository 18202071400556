import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router";
import { Authentication } from '@hsi/ui-core'; 
import { UtilityService } from "@core/services/utility.service";
import { Router } from '@angular/router'; 

@Injectable({
    providedIn: 'root'
})
export class AuthenticatedUserGuard implements CanActivate {
    constructor(
        private authenticationService: Authentication.Core.AuthenticationService,
        private utiltiyService: UtilityService,
        private router:Router){ }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean{
        if(!this.authenticationService.authenticatedUser)
        {
            this.utiltiyService.say("Authentication Required.");
            this.router.navigate(['/login']);
            return false;
        } 
        return true;
    }
}