import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticatedUserGuard } from '@core/guards/authenticated-user.guard';

const routes: Routes = [
  {
    path: 'login',
    data: { animation: 'login' },
    loadChildren: () => import('./pages/login-page/login-page.module').then((m) => m.LoginPageModule)
  }, 
  {
    path: 'home',
    data: { animation: 'home' },
    loadChildren: () => import('./pages/home-page/home-page.module').then((m) => m.HomePageModule),
    canActivate: [AuthenticatedUserGuard]
  },
  {
    path: 'course/index',
    data: { animation: 'course-search' },
    loadChildren: () => import('./pages/course-search-page/course-search-page.module').then((m) => m.CourseSearchPageModule),
    canActivate: [AuthenticatedUserGuard]
  }, 
  {
    path: 'course/:courseId',
    data: { animation: 'course-details' },
    loadChildren: () => import('./pages/course-details-page/course-details-page.module').then((m) => m.CourseDetailsPageModule),
    canActivate: [AuthenticatedUserGuard]
  }, 
  {
    path: 'asset/:type',
    data: { animation: 'asset-index' },
    loadChildren: () => import('./pages/asset-index-page/asset-index-page.module').then((m) => m.AssetIndexPageModule),
    canActivate: [AuthenticatedUserGuard]
  },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  }];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
