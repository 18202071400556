export const environment = {
    api: {
        administration: 'https://ms-administration-dev.azurewebsites.net',
        trainingResources: 'https://ms-trainingresources-dev.azurewebsites.net'
    },
    authentication: {
        hsiAuthServer: 'https://hsiauth-dev.azurewebsites.net',
        clients: {
            hsiResourceOwner: {
            secret: 'BA5D5E55-BC8D-46C4-9AD9-6CD828614566',
            scope: 'SummitScope'   
            }
        }
    },
    configuration: 'development', 
    microserviceStorage: 'https://microservicestoragedev.blob.core.windows.net',
    permissions: { 
    },
    production: true,
    title: 'Training Playlist Studio - Development',
    videoPlayer: 'https://hsivideoplayer-dev.azurewebsites.net'
}