
<div class="flex flex-col gap-4 h-full"> 
    <div class="select-none flex gap-2 items-center" *ngIf="header">  
        <mat-icon 
            [ngClass]="{'text-red-500': status === 'error', 
                        'text-[#0079be]': status === 'default'}" 
            class="mat-icon !h-auto !w-auto  notranslate material-icons mat-icon-no-color" style="font-size:48px;"> 
            <ng-content select="[header-icon]"></ng-content>
        </mat-icon>  
        <div class="flex flex-col flex-1 min-w-0">
            <div 
                [ngClass]="{'text-red-500': status === 'error', 
                            'text-[#0079be]': status === 'default'}" 
                    class="text-xl font-bold overflow-hidden overflow-ellipsis whitespace-nowrap">
                <ng-content select="[header-title]"></ng-content>
            </div>
            <div class="text-sm overflow-hidden overflow-ellipsis whitespace-nowrap"> 
                <ng-content select="[header-description]"></ng-content>
            </div>
        </div> 
        <div class="flex flex-row">
            <ng-content select="[header-actions]"></ng-content>
        </div> 
    </div>   
    <div class="flex flex-col gap-4 w-full h-full overflow-auto">
        <ng-content select="[body-content]"></ng-content>
    </div>
</div>