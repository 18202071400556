import { Authentication } from '@hsi/ui-core';

/**
 * Orchestrates @hsi/ui-core WebStorageTokenProvider composition.
 */
export let webStorageTokenProvider = {
    provide: Authentication.Infrastructure.WebStorageTokenProvider,
    deps: [],
    useFactory: () => { 
      return new Authentication.Infrastructure.WebStorageTokenProvider('authToken');             
    },
};