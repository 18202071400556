<h2 mat-dialog-title>
    <div class="flex flex-row items-center ">
        <span class="material-icons">system_update</span>
        Update Application
    </div>
</h2> 
<mat-dialog-content class="mat-typography"> 
    <p>An application update is available for install.  Click 'Update' to install now otherwise the update will be applied automatically the next time you start your application.</p>
</mat-dialog-content> 
<mat-dialog-actions> 
    <div class="flex flex-row gap-2 w-full">
        <button (click)="update()" mat-flat-button color="primary" class="w-full">Update</button> 
        <button mat-flat-button mat-dialog-close color="warn" class="w-full">Cancel</button>
    </div>
</mat-dialog-actions>  