  <div class="!bg-transparent w-full select-none">
    <div class="absolute w-full">
      <mat-progress-bar *ngIf="appStateService.loading" mode="indeterminate"></mat-progress-bar>
    </div>
    <div class="!bg-transparent w-full px-4 sm:px-8"> 
      <div class="container mx-auto flex flex-col py-4 min-h-[72px]"> 
        <!--row 1-->
        <div class="flex flex-row text-white" *ngIf="authenticationService.authenticatedUser && this.router.url !== '/login'">
          <!--left side-->
          <div class="text-xl sm:text-2xl min-w-0" > 
            <div class="flex flex-row items-center min-w-0 min-h-[40px]">
              <button class="!hidden sm:!block" mat-icon-button aria-label="Menu" (click)="appStateService.displayNavigation = !appStateService.displayNavigation">
                  <mat-icon class="text-shadow-md text-white">{{appStateService.displayNavigation ? 'menu_open': 'menu'}}</mat-icon>
              </button>
              <span class="ml-1 sm:ml-0 font-bold overflow-hidden overflow-ellipsis whitespace-nowrap text-shadow-md">{{title}}</span>        
            </div>
          </div>
          <!--right side-->
          <div class="flex flex-row justify-end items-start flex-1">  
              <button *ngIf="!appStateService.connectivity" mat-icon-button aria-label="Offline Mode">
                <mat-icon class="text-shadow-md text-yellow-500" matTooltip="Offline Mode" matTooltipPosition="below">wifi_off</mat-icon>
              </button>      
              <button *ngIf="appStateService.updateAvailable" mat-icon-button aria-label="Update Available"  (click)="updateDialog();">
                <mat-icon class="text-shadow-md text-white" matBadge="1"  matBadgeColor="warn" matTooltip="Update Available" matTooltipPosition="below">system_update</mat-icon>
              </button> 
              <button *ngIf="appStateService.installable" mat-icon-button aria-label="Install App" (click)="appStateService.installPrompt();">
                <mat-icon class="text-shadow-md text-white" matTooltip="Install App" matTooltipPosition="below">download_for_offline</mat-icon>
              </button>
              <button class="!hidden sm:!block" mat-icon-button aria-label="Full Screen" (click)="toggleFullScreen()">
                <mat-icon class="text-shadow-md text-white" matTooltip="Full Screen" matTooltipPosition="below">fullscreen</mat-icon>
              </button>
              <button  class="" *ngIf="this.router.url !== '/login' && authenticationService.authenticatedUser" mat-icon-button aria-label="Log Out" routerLink="/login">
                <mat-icon class="text-shadow-md text-white" matTooltip="Log Out" matTooltipPosition="below">logout</mat-icon>
              </button> 
          </div> 
        </div> 
      </div>
  </div> 
</div> 