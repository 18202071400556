 
<div class="z-0  bg-[#0079be] w-full h-[72px] absolute"></div>
<div class="z-0 top-[72px] right-0 left-0 absolute w-full h-[75px] overflow-hidden" style="overflow: hidden;" >
  <svg viewBox="0 0 500 150" preserveAspectRatio="none" style="height: 100%; width: 100%;"><path d="M-9.31,121.88 C129.51,16.28 238.99,154.44 567.43,31.08 L509.87,-10.36 L0.00,0.00 Z" style="stroke: none; fill: #0079be;"></path></svg>
</div> 
<mat-sidenav-container class="!bg-transparent l-0 r-0 w-full h-full transform duration-300" [ngClass]="{'mobile': authenticationService.authenticatedUser && utilityService.screenWidth < 640,'slim': appStateService.slimNavigation, 'open': appStateService.displayNavigation && authenticationService.authenticatedUser}">
  <mat-sidenav #sidenav mode="side" [opened]="(appStateService.displayNavigation && authenticationService.authenticatedUser) ? true : false" [fixedInViewport]="true" class="sidenav !transform !duration-300 overflow-x-hidden"  *ngIf="authenticationService.authenticatedUser" style="visibility:visible !important;">
      <!--navigation menu-->
      <div class="flex flex-col h-full">
        <app-navigation class="select-none flex-1 block overflow-y-auto overflow-x-hidden transform duration-300"></app-navigation> 
        <div class="bg-[#0079be] w-full flex flex-col cursor-pointer justify-start p-2" matRipple (click)="appStateService.slimNavigation = !appStateService.slimNavigation"> 
          <div class="flex justify-center">
              <mat-icon class="text-white transform duration-300 rotate-90">{{ appStateService.slimNavigation ?'unfold_more':'unfold_less'}}</mat-icon>
          </div>
      </div>
    </div>
  </mat-sidenav> 
  <mat-sidenav-content class="!transform !duration-300" [ngClass]="{'!w-auto':appStateService.slimNavigation || utilityService.screenWidth >= 640, '!w-full': !appStateService.slimNavigation &&  utilityService.screenWidth < 640}">
      <!--header-->
      <app-toolbar class="select-none"></app-toolbar> 
      <!--body--> 
      <div class="px-4 sm:px-8" [@fadeAnimation]="getAnimationData(routerOutlet)"> 
          <router-outlet #routerOutlet="outlet"></router-outlet> 
      </div>
      <!--footer-->
      <div class="flex items-center justify-center text-sm p-8">
        <span>© 2021 Health & Safety Institute</span>
      </div> 
  </mat-sidenav-content> 
</mat-sidenav-container>  
<app-toolbar-mobile class="relative transform duration-300" [ngClass]="{'mobile': authenticationService.authenticatedUser && utilityService.screenWidth < 640}"></app-toolbar-mobile>
<!--handler components-->
<app-update></app-update>
<app-installation></app-installation>
<app-connectivity></app-connectivity>
  