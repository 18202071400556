<div class="absolute z-20 bottom-0 left-0 right-0 h-[88px] overflow-hidden bg-[rgba(51,51,51)]">
    <div class="flex flex-row items-center justify-center h-full w-full overflow-auto cursor-pointer select-none"> 
        <div [ngClass]="{'!text-white': appStateService.displayNavigation, '!justify-start pt-3': utilityService.platform === 'ios'}" class="pb-1 flex-col h-full justify-center items-center overflow-hidden flex flex-1 text-gray-400" aria-label="Menu" (click)="appStateService.displayNavigation = !appStateService.displayNavigation">
            <mat-icon class="!h-auto !w-auto">{{appStateService.displayNavigation ? 'menu_open': 'menu'}}</mat-icon>
            Menu
        </div> 
        
        <!-- <div [ngClass]="{'!justify-start pt-3': utilityService.platform === 'ios'}" [routerLinkActive]="['active']" routerLink="/home" class="focus:outline-none pb-1 flex-col h-full justify-center items-center overflow-hidden flex flex-1 text-gray-400 focus:text-white transition duration-300" aria-label="Home">
            <mat-icon class="!h-auto !w-auto">home</mat-icon>
            Home
        </div>      -->
        
        <div [ngClass]="{'!justify-start pt-3': utilityService.platform === 'ios'}" [routerLinkActive]="['active']" routerLink="/course/index" class="focus:outline-none pb-1 flex-col h-full justify-center items-center overflow-hidden flex flex-1 text-gray-400 focus:text-white transition duration-300" aria-label="Course Index">
            <mat-icon class="!h-auto !w-auto">manage_search</mat-icon>
            Index
        </div>    
        
        <div [ngClass]="{'!justify-start pt-3': utilityService.platform === 'ios'}" [routerLinkActive]="['active']" routerLink="/asset/index" class="focus:outline-none pb-1 flex-col h-full justify-center items-center overflow-hidden flex flex-1 text-gray-400 focus:text-white transition duration-300" aria-label="Manage Assets">
            <mat-icon class="!h-auto !w-auto">perm_media</mat-icon>
            Assets
        </div>    
        
        <a target="_blank" [href]="videoPlayerUrl" [ngClass]="{'!justify-start pt-3': utilityService.platform === 'ios'}" class="focus:outline-none pb-1 flex-col h-full justify-center items-center overflow-hidden flex flex-1 text-gray-400 focus:text-white transition duration-300" aria-label="Video Player">
            <mat-icon class="!h-auto !w-auto">video_library</mat-icon>
            Video Player
        </a>    

         
        <!--         
        <div [ngClass]="{'!justify-start pt-3': utilityService.platform === 'ios'}" (click)="settingsDialog()" aria-label="" class="focus:outline-none pb-1 flex-col h-full justify-center items-center overflow-hidden flex flex-1 text-gray-400 focus:text-white transition duration-300" aria-label="Settings">
            <mat-icon class="!h-auto !w-auto">phonelink_setup</mat-icon>
            Setup
        </div>
        -->
    </div>
</div>