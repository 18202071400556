import { Component, HostListener } from '@angular/core'; 
import { AppStateService } from '@core/services/app-state.service';
import { UtilityService } from '@core/services/utility.service';

@Component({
  selector: 'app-connectivity',
  template: '',
  styleUrls: []
})
export class ConnectivityComponent {

  constructor(
    private appStateService:AppStateService,
    private utilityService: UtilityService) { }

  @HostListener('window:offline')
  setNetworkOffline(): void {
    this.appStateService.connectivity = false;
    this.utilityService.say('The application is now running in offline mode.  Certain features will be unavailable until connectivity is restored.');
  } 
  @HostListener('window:online')
  setNetworkOnline(): void {
    this.appStateService.connectivity = true;
    this.utilityService.say('Connectivity has been reestablished.  All application features have been restored');
  } 
} 