import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight'
})
export class HighlightPipe implements PipeTransform {
  transform(value: any, text: any, cssClass:any): any {
    if (!text) {
      return value;
    } 
    const regex = new RegExp(text, 'gi');
    const match = value.match(regex);

    if (!match) {
      return value;
    } 
    return value.replace(regex, `<span class="${cssClass}">${match[0]}</span>`);
  }
}
