import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button'; 
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatLegacyAutocompleteModule as MatAutocompleteModule} from '@angular/material/legacy-autocomplete';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatLegacyRadioModule as MatRadioModule} from '@angular/material/legacy-radio';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select'; 
import {MatLegacySlideToggleModule as MatSlideToggleModule} from '@angular/material/legacy-slide-toggle';
import {MatLegacyMenuModule as MatMenuModule} from '@angular/material/legacy-menu';
import {MatSidenavModule} from '@angular/material/sidenav'; 
import {MatLegacyListModule as MatListModule} from '@angular/material/legacy-list'; 
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {MatStepperModule} from '@angular/material/stepper';
import {MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs'; 
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatLegacyChipsModule as MatChipsModule} from '@angular/material/legacy-chips';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {MatLegacyProgressBarModule as MatProgressBarModule} from '@angular/material/legacy-progress-bar';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {MatLegacySnackBarModule as MatSnackBarModule} from '@angular/material/legacy-snack-bar';
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';
import {MatSortModule} from '@angular/material/sort';
import {MatLegacyPaginatorModule as MatPaginatorModule} from '@angular/material/legacy-paginator';  
import {MatTreeModule} from '@angular/material/tree';
import {MatRippleModule} from '@angular/material/core'; 
import {ScrollingModule} from '@angular/cdk/scrolling';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet'; 
import { UiScannerComponent } from './components/ui-scanner/ui-scanner.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { CardLayoutComponent } from './components/card-layout/card-layout.component'; 
import { MatGridListModule } from '@angular/material/grid-list';

const modules = [   
  CommonModule, 
  DragDropModule,
  MatGridListModule,
  MatCheckboxModule,
  MatBottomSheetModule,
  MatCheckboxModule,
  MatButtonModule,
  MatInputModule,
  MatAutocompleteModule,
  MatDatepickerModule,
  MatFormFieldModule,
  MatRadioModule,
  MatSelectModule, 
  MatSlideToggleModule,
  MatMenuModule,
  MatSidenavModule, 
  MatListModule, 
  MatBadgeModule,
  MatCardModule,
  MatStepperModule,
  MatTabsModule, 
  MatButtonToggleModule,
  MatChipsModule,
  MatIconModule,
  MatProgressSpinnerModule,
  MatProgressBarModule,
  MatDialogModule,
  MatTooltipModule,
  MatSnackBarModule,
  MatTableModule,
  MatTreeModule,
  MatSortModule,
  MatPaginatorModule,
  ScrollingModule,
  MatRippleModule 
];

@NgModule({
  declarations: [ 
    UiScannerComponent, 
    ConfirmationDialogComponent,
    CardLayoutComponent
  ],
  imports: modules,
  exports: [
    CardLayoutComponent,
    ...modules 
  ]
})   
export class UiModule { }
