import { Component, OnInit } from '@angular/core'; 
import { AppStateService } from '@core/services/app-state.service'; 
import { UtilityService } from '@core/services/utility.service';  

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  constructor(     
    public appStateService:AppStateService,
    public utilityService:UtilityService) { } 
  appTheme:string = "lightMode";
  ngOnInit(): void { 
  }   
}  