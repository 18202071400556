<div class="bg-gray-300 h-[4px] w-full"></div>
<div [ngClass]="{'slim': appStateService.slimNavigation}" class="flex flex-col whitespace-nowrap">       
    <div class="w-full flex flex-col">  
        <div class="transform hover:bg-gray-200  duration-500 flex gap-2 px-2 p-2 pt-1 items-center">
            <img id="logo" src="/assets/hsi-logo.png" class="my-2 w-[54px]"/>    
            <div class="flex-1">
                <div id="nav-user" class="pt-2">
                    <div class="text-gray-600 whitespace-nowrap font-bold leading-3">{{authenticationService.authenticatedUser.name}}</div> 
                    <small class="text-gray-500 flex flex-row flex-1"> 
                        {{authenticationService.authenticatedUser.customerName}} 
                    </small> 
                </div>
            </div> 
            <div id="nav-dropdown"> 
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="User Menu">
                    <mat-icon>expand_more</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item  *ngIf="appStateService.installable"  aria-label="Install App" (click)="appStateService.installPrompt();">
                      <mat-icon>download_for_offline</mat-icon>
                      <span>Install App</span>
                    </button>
                    <button mat-menu-item  *ngIf="appStateService.updateAvailable"  aria-label="Install App" (click)="appStateService.installPrompt();">
                      <mat-icon matBadge="1"  matBadgeColor="warn">system_update</mat-icon>
                      <span>Update Available</span>
                    </button>
                    <button mat-menu-item aria-label="Close Menu" (click)="appStateService.displayNavigation = false;">
                        <mat-icon class="text-white">menu_open</mat-icon>
                        <span>Close Menu</span>
                    </button>
                    <button mat-menu-item routerLink="/login">
                      <mat-icon>logout</mat-icon>
                      <span>Log Out</span>
                    </button> 
                  </mat-menu>
            </div>  
        </div> 
        <!-- <a [routerLinkActive]="['active']" matRipple class="transform hover:bg-gray-200 duration-500 flex gap-4 p-2 items-center" routerLink="/home"  matTooltip="{{appStateService.slimNavigation ? 'Home' : ''}}" matTooltipPosition="right">
            <mat-icon class="text-gray-500 ml-2 text-1xl !h-auto !w-auto rounded-md bg-gray-300 p-2">home</mat-icon> 
            <span>Home</span>
        </a>        -->
        <a [routerLinkActive]="['active']" matRipple class="transform hover:bg-gray-200 duration-500 flex gap-4 p-2 items-center" routerLink="/course/index"  matTooltip="{{appStateService.slimNavigation ? 'Course Index' : ''}}" matTooltipPosition="right">
            <mat-icon class="text-gray-500 ml-2 text-1xl !h-auto !w-auto rounded-md bg-gray-300 p-2">manage_search</mat-icon> 
            <span>Course Index</span>
        </a>       
        <a [routerLinkActive]="['active']" matRipple class="transform hover:bg-gray-200 duration-500 flex gap-4 p-2 items-center" routerLink="/asset/index"  matTooltip="{{appStateService.slimNavigation ? 'Asset Manager' : ''}}" matTooltipPosition="right">
            <mat-icon class="text-gray-500 ml-2 text-1xl !h-auto !w-auto rounded-md bg-gray-300 p-2">perm_media</mat-icon> 
            <span>Asset Manager</span>
        </a>       
        <a matRipple class="transform hover:bg-gray-200 duration-500 flex gap-4 p-2 items-center" target="_blank" [href]="videoPlayerUrl" matTooltip="{{appStateService.slimNavigation ? 'Video Player' : ''}}" matTooltipPosition="right">
            <mat-icon class="text-gray-500 ml-2 text-1xl !h-auto !w-auto rounded-md bg-gray-300 p-2">video_library</mat-icon> 
            <span>Video Player</span>
        </a>       
        <!-- <a (click)="settingsDialog()" matRipple class="cursor-pointer !flex transform hover:bg-gray-200  duration-500 gap-4 p-2 items-center" matTooltip="{{appStateService.slimNavigation ? 'App Settings' : ''}}" matTooltipPosition="right">
            <mat-icon class="text-gray-500 ml-2 text-1xl !h-auto !w-auto rounded-md bg-gray-300 p-2">phonelink_setup</mat-icon> 
            <span>App Settings</span>
        </a>  -->
    </div> 
</div> 