import { Authentication, Common } from '@hsi/ui-core';
import { environment } from '@environement/environment';
import TokenExpirationHandler from '@core/handlers/token-expiration-handler';

/**
 * Orchestrates @hsi/ui-core AuthenticationService composition.
 */
export let authServiceProvider = {
    provide: Authentication.Core.AuthenticationService,
    deps: [
      TokenExpirationHandler, 
      Authentication.Infrastructure.WebStorageTokenProvider,
      Common.Infrastructure.MicroserviceClient
    ],
    useFactory: (
      tokenExpirationHandler: TokenExpirationHandler,
      webStorageTokenProvider: Authentication.Infrastructure.WebStorageTokenProvider,
      microserviceClient: Common.Infrastructure.MicroserviceClient
      ) => { 
      const authClient = new Authentication.Infrastructure.HsiResourceOwnerClient(
        microserviceClient,
        environment.authentication.hsiAuthServer, 
        environment.authentication.clients.hsiResourceOwner.secret,
        environment.authentication.clients.hsiResourceOwner.scope
      );        
      const administrationRepository = new Common.Infrastructure.AdministrationMicroservice(
        microserviceClient, 
        environment.api.administration); 
      return new Authentication.Core.AuthenticationService(
        authClient, 
        administrationRepository,
        webStorageTokenProvider,
        tokenExpirationHandler.onExpiration); 
    },
};