import { Component } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { AppStateService } from '@core/services/app-state.service';

@Component({
  selector: 'app-update',
  template: '',
  styleUrls: []
})
export class UpdateComponent {
  constructor(
    private updates:SwUpdate,
    appStateService:AppStateService) { 
      updates.available.subscribe(event => {  
        console.log('Update available - Version:', event.available);
        console.log('Current Version:', event.current);
        appStateService.updateAvailable = true;
        appStateService.updatePrompt = ()=> {
          this.updates.activateUpdate().then(() => document.location.reload());
        };
      });
      updates.activated.subscribe(event => {  
        console.log('Previous Version:', event.previous);
        console.log('Update Applied.  Current Version:', event.current);
        appStateService.updateAvailable = false; 
      });
    }     
    
} 